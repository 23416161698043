import { useState, useEffect } from 'react';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { Dashboard, Embed, models, service } from 'powerbi-client';
import Box from "@mui/material/Box";
import { DashboardEmbedConfig, ContentInit } from 'common/Types/EmbeddedContentTypes';
import { embedConfigRequest } from '../embeddedContentFrame/embeddedContentAPI';
import styles from '../embeddedContentFrame/embeddedContentFrame.module.scss';
import { useAppSelector } from 'state/hooks';
import { selectContentInit } from '../embeddedContentFrame/embeddedContentFrameSlice';

export const EmbeddedDashboard = (): JSX.Element => {
  const dashboardInit: ContentInit = useAppSelector(selectContentInit);

  const [powerbiDashboard, setDashboard] = useState<Dashboard>();
  const [embedConfig, setEmbedConfig] = useState<DashboardEmbedConfig>({
    canEdit: false,
    type: 'dashboard',
    embedToken: {
      expiration: '',
      token: '',
      tokenId: ''
    },
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    pageView: 'fitToWidth',
    minutesToExpiration: 60
  });

  const getEmbeddedDashboard = (embeddedDashboard: Embed) => {
    const dash = embeddedDashboard as Dashboard;
    setDashboard(dash);
  }

  useEffect(() => {
    const getEmbedConfig = async () => {
      const config = await embedConfigRequest({
        requestedContent: dashboardInit,
        reportPackName: "NA"
      });

      setEmbedConfig((state) => {
        return {
          ...state,
          ...config,
          accessToken: config?.embedToken.token
        }
      });
    }

    if (!embedConfig.accessToken) {
      getEmbedConfig();
    }
  }, [embedConfig.accessToken]);

  useEffect(() => {
    if (powerbiDashboard) powerbiDashboard.setComponentTitle('Dashboard');
  }, [powerbiDashboard]);

  const eventHandlersMap: Map<string, EventHandler> = new Map([
    ['loaded', async () => {

    }],
    ['rendered', () => {

    }],
    ['error', (event?: service.ICustomEvent<any>) => {
      if (event) {
        console.error(`an error has occured while processing the report: ${event.detail}`);
      }
    }]
  ]);

  return (
    <Box
      role='document'
      aria-label='dashboard container'
      className={styles.powerbi_embedded_content_container}
    >
      <PowerBIEmbed
        embedConfig={embedConfig}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={getEmbeddedDashboard}
        cssClassName={styles.embedded_content_target}
      />
    </Box>
  )
}