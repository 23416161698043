import axios from 'axios';
import { Sponsor, SponsorCard, MgmtMap, SponsorAppRowModel, SponsorAppReport, ElectionsDTO, SponsorApplication } from '../../common/Types/ReportMgmtTypes';
import configData from 'config.json';

export const sponsorAppsRequest = async (sponsor: Sponsor): Promise<SponsorCard> => {
  const returnAppRowModels = (data: SponsorApplication[]): MgmtMap<SponsorAppRowModel> => {
    const mapReports = (reports: SponsorAppReport[]): MgmtMap<SponsorAppReport> => {
      const result: MgmtMap<SponsorAppReport> = reports.reduce((map: MgmtMap<SponsorAppReport>, report: SponsorAppReport) => {
        map[report.id] = report;
        return map;
      }, {})

      return result;
    }
        
    const result = data.reduce((map: MgmtMap<SponsorAppRowModel>, item: SponsorApplication) => {
      const {name, applicationId, applicationDescription, sponsorName, reports} = item;
      const generatedId = `${item.applicationId}_${item.sponsorName}_${item.name}`;
      const app: SponsorAppRowModel = {
        id: generatedId,
        name,
        applicationId,
        applicationDescription,
        sponsorName,
        reports: mapReports(Object.values(reports))
      }
      map[generatedId] = app;
      return map;
    }, {})
    return result;
  }
  
  try {
    return await axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`, {
      params: {
        code: configData.ENDPOINT_ALX_REQUEST_CODE
      }
    }).then((response) => {
      const preppedApps = returnAppRowModels(response.data as SponsorApplication[]);
      const returnActiveRows = (apps: SponsorAppRowModel[]): string[] => {
        const result: string[] = apps.reduce((list: string[], app: SponsorAppRowModel) => {
          if (Object.values(app.reports).some((report: SponsorAppReport) => report.isActive === true)){
            list.push(app.id);
          }

          return list;
        }, [])

        return result;
      }

      const newCard: SponsorCard = {
        cardSponsor: sponsor,
        cardApps: preppedApps,
        selectedAppRows: returnActiveRows(Object.values(preppedApps)),
        pendingReportPackageElections: {},
        allCols: []
      }

      return newCard
    })
  } catch (err) {
    const error = (err as Error);
    throw new Error(`The requested sponsor app was not returned. Error: ${error.message}`);
  }
}

export const sponsorsRequest = async (): Promise<MgmtMap<SponsorCard>> => {
  try {
    const returnCardMap = (sponsors: Sponsor[]): MgmtMap<SponsorCard> => {
      const result = sponsors.reduce((map: MgmtMap<SponsorCard>, sponsor: Sponsor) => {
        const card: SponsorCard = {
          cardSponsor: sponsor,
          cardApps: {},
          selectedAppRows: [],
          pendingReportPackageElections: {},
          allCols: []
        } 
        map[sponsor.id] = card;
        return map;
      }, {});
      return result;
    }

    return await axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/sponsor`, {
      params: {
        code: configData.ENDPOINT_ALX_REQUEST_CODE
      }
    }).then((response) => {
      return returnCardMap(response.data);
    })
  } catch (err) {
    const error = err as Error;
    throw new Error(`The request for sponsors failed. Error: ${error.message}`);
  }
}

export const saveReportPackageElections = async (electionsDto: ElectionsDTO) => {
  try {
    return await axios.post(`${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${electionsDto.sponsorId}`, 
      electionsDto.electionList,
      {
        params: {
          code: configData.ENDPOINT_ALX_REQUEST_CODE
        }
      }
    ).then((response) => {
      return response.data;
    })
  } catch (err) {
    const error = err as Error;
    throw new Error(`the attempt to save your elections has failed: Error: ${error.message}`);
  }
}