import Box from "@mui/material/Box";
import { useIdleTimer }from 'react-idle-timer';
import { BladeMenu } from "components/bladeMenu/BladeMenu";
import { EmbeddedContentFrame } from "components/embeddedContentFrame/EmbeddedContentFrame";
import { AppMenu } from "components/appMenu/AppMenu";
import styles from "App.module.scss";
import { useAppSelector } from "state/hooks";
import { selectMenuReady } from "components/bladeMenu/bladeMenuSlice";
import { useState } from "react";
import { TimedClientDialog } from "components/timedClientDialog/TimedClientDialog";
import CircularProgress from "@mui/material/CircularProgress";
import userManager from 'common/Utils/userManager';
import { selectOidcUser } from "gatewaySlice";
import axios from 'axios';
import JSONbig from 'json-bigint';
const JSONBigString = JSONbig({'storeAsString': true});
axios.defaults.transformResponse = [(data) => {    
  return JSONBigString.parse(data);
}];


const App = (): JSX.Element => {
  const user = useAppSelector(selectOidcUser);
  const ready = useAppSelector(selectMenuReady);
  const [isIdle, setIsIdle] = useState<boolean>(false);
  let expiresin;
  let idleTime_value

  const idleTime = String(user?.expires_in)
  if(idleTime > "0"){
   sessionStorage.setItem("idleTime_key",  idleTime);
   idleTime_value = sessionStorage.getItem("idleTime_key");
   expiresin =  Math.floor(Number(idleTime_value) * 1000);
  }
  else{
   idleTime_value = sessionStorage.getItem("idleTime_key");
   expiresin =  Math.floor(Number(idleTime_value) * 1000);
  }

  const handlePromptAction = async () => {
    setIsIdle(false);
    idleTimer.start();
  }

  const handlePromptReject = async () => {
    userManager.signoutRedirect();
    userManager.removeUser();
  }

  const idleTimer = useIdleTimer({
    timeout: expiresin,
    onIdle: () => setIsIdle(true),
    startOnMount: true,
    stopOnIdle: true,
  });

  const embeddedContentFrame = () => {
    return ready ? <EmbeddedContentFrame /> : <CircularProgress />;
  }

  return (
    <Box className={styles.app}>
      <header className={styles.app_header}>
        <AppMenu />
      </header>
      <Box className={styles.app_body}>
        <BladeMenu />
        {embeddedContentFrame()}
        {isIdle && 
          <TimedClientDialog
            time={30}
            title={"your session is about to expire."}
            eventDescription={"your session will expire."}
            dialog={'Click "Continue" to continue with your session or end your session by clicking "Log Out".'}
            acceptText={"Continue"}
            rejectText={"Log Out"}
            acceptAction={handlePromptAction}
            rejectAction={handlePromptReject}
          />
        }
      </Box>
    </Box>
  );
};

export default App;
