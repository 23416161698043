import axios from 'axios';
import configData from 'config.json';

export const topNavItemsRequest = async () => {
  try {
    return await axios.get(`${configData.GATEWAY_CS_URL}/api/Menus/CentralServices_MainNav`, {
        params: {
          code: configData.ENDPOINT_ALX_REQUEST_CODE
        }
      }).then((response) => {
        const resp = Object.keys(response.data.subMenus).map((key: string) => ({
          name: response.data.subMenus[key].name,
          displayName: response.data.subMenus[key].displayName,
          isAbsoluteUrl: response.data.subMenus[key].isAbsoluteUrl,
          sourceUrl: response.data.subMenus[key].sourceUrl,
          order: response.data.subMenus[key].orderId,
          hidden:response.data.subMenus[key].hidden
        }));
        return resp;
      }).catch((err) => {
        const error = (err as Error);
        throw new Error(`The requested AppBar menu data was not returned. Error: ${error.message}`)
      });
  } catch (err) {
    const error = err as Error;
    throw new Error(`request for AppBar menu items failed: ${error.message}`);
  }
}