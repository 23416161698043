import axios from 'axios';
import configData from 'config.json';

export const bladeMenuItemsRequest = async () => {
  try {
    return await axios.get(`${configData.ENDPOINT_ALX_APIBASEURL}/get-navigation`, {
      params: {
        code: configData.ENDPOINT_ALX_REQUEST_CODE
      }
    }).then((response) => {
      return response.data;
    }).catch((err) => {
      const error = (err as Error);
      throw new Error(`The requested blade menu data was not returned. Error: ${error.message}`)
    });
  } catch(err) {
    const error = err as Error;
    throw new Error(`request for blade menu items failed: ${error.message}`);
  }
}