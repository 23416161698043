import axios from "axios";
import React from 'react';
import { Routes, Route } from "react-router-dom";
import CallbackPage from "components/callbackPage/CallbackPage";
import Login from "components/login/Login";
import App from "App";
import { useAppSelector, useAppDispatch } from "state/hooks";
import { fetchUserSession, selectOidcUser, selectSessionId, selectUserSessionState } from "gatewaySlice";
import configData from 'config.json';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Container } from "@mui/material";

const Gateway = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const sessionSetup = useAppSelector(selectUserSessionState);

  const gatewayElement = () => {
    if (!user || user.expired) {
      return <Login />;
    } else if (!user?.profile["endpoint:topnav"].includes(configData.ENDPOINT_ALX_APP_NAME)) {
      window.location.href = configData.GATEWAY_ERR_REDIRECT_URL;
      return null;
    } else if (user.profile) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user?.access_token}`;
      axios.defaults.headers.common["SessionId"] = sessionId;
      axios.defaults.headers.common["x-functions-key"] = configData.ENDPOINT_ALX_REQUEST_CODE;

      if (sessionSetup) {
        return <App />;
      } else {
        const setUserSession = async () => {
          await dispatch(fetchUserSession());
        };
        setUserSession();
        return (
          <React.Fragment>
            <Container maxWidth="sm">
              <Box display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh">
                <CircularProgress />
              </Box>
            </Container>
          </React.Fragment>);
      }


    }
  };

  return (
    <Routes>
      <Route path="/*" element={gatewayElement()} />
      <Route path="/callback" element={<CallbackPage />} />
    </Routes>
  );
};

export default Gateway;

