import { Routes, Route, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useSearchParams } from "react-router-dom";
import { Overview } from '../overview/Overview';
import { EmbeddedReport } from '../embeddedReport/EmbeddedReport';
import { EmbeddedDashboard } from '../embeddedDashboard/EmbeddedDashboard';
import UserInfo from '../userInfo/UserInfo';
import styles from './embeddedContentFrame.module.scss';
import { selectAllReportMenuItems } from '../bladeMenu/bladeMenuSlice';
import { useAppSelector } from 'state/hooks';
import { BladeMenuItem } from 'common/Types/BladeMenuTypes';
import { FavoriteControl } from '../favoriteControl/FavoriteControl';
import { ReportManagement } from '../reportManagement/ReportManagement';
import Typography from '@mui/material/Typography';
import { selectContentRefreshDate } from './embeddedContentFrameSlice';
import { formatDate } from 'common/Utils/date';
import React from 'react';
import  ExportPowerBiReport from '../embeddedContentExport/EmbeddedContentExport';

export const EmbeddedContentFrame = (): JSX.Element => {
  const allMenuOptions = useAppSelector(selectAllReportMenuItems);
  const refreshDate = useAppSelector(selectContentRefreshDate);

  let [searchParams] = useSearchParams();
  let activePageName = searchParams.get("contentPage") ?? "";
  const {pathname} = useLocation();

  const returnMatchItem = (): BladeMenuItem | undefined => {
    return allMenuOptions.find((menuItem) =>
      menuItem.pages?.find((page) =>
        Object.values(page).includes(activePageName)
      )
    );
  };

  function returnPageItem(contentName: string | null): string {
    if (!contentName) return '';
    const menu = returnMatchItem();
    const reportPage = menu?.pages?.find((page) =>
      Object.values(page).includes(contentName)
    );
    return reportPage?.displayName ?? '';
  }

  const preferredItem = returnMatchItem();
  const selectedPage = returnPageItem(activePageName);

  const favoriteControl = () => {
    if (!!preferredItem) {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ marginRight: "1vw" }}>Mark as Favorite</Typography>
          <FavoriteControl
            preferredItem={preferredItem}
            keyName={activePageName}
            displayName={selectedPage}
            location="Report Page"
          />
        </Box>
      );
    }
  }

   const DownloadButton = () =>{
    if (!!preferredItem) {
      return (
        <ExportPowerBiReport></ExportPowerBiReport>
      )
    }
   }
  
  const returnActiveReportPageTitle = () => {
    if (!!preferredItem) {
      return preferredItem.pages?.find(page => page.name === activePageName)?.displayName;
    } else {
      return 'Analytics';
    }
  }

  const returnLocationTitle = () => {
    switch (pathname) {
      case('/report-management'):
        return 'Report Management';
      case('/'):
        return 'Analytics';
      default:
        return returnActiveReportPageTitle();
    }
  }

  const controlledContentFrame = () => {
    return (
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="report/*" element={<EmbeddedReport />} />
        <Route path="dashboard" element={<EmbeddedDashboard />} />
        <Route path="user-info" element={<UserInfo />} />
        <Route path="report-management" element={<ReportManagement />} />
      </Routes>
    );
  };

  const refreshDateComponent = () => {
    return (
      <React.Fragment>
        <Typography className={`${styles.refresh_readout}`}>Last refreshed {formatDate(refreshDate, {shortMonth: true, internationalFormat: true})}</Typography>
      </React.Fragment>
    );
  }

  return (
    <Box className={styles.content_frame}>
      <Box sx={{ width: "calc(100% - 33px)" }} className={styles.frame_header}>
        <Box sx={{ display: "flex" , justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Typography className={styles.frame_title}>{returnLocationTitle()}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!!preferredItem && refreshDateComponent()}
            </Box>
          </Box>   
          {favoriteControl()}
        </Box>
        <Divider sx={{ margin: "16px 0 1em" }} />
      </Box>
      <Box style={{ display: "flex",alignSelf: 'flex-end'}}>
        {DownloadButton()}
      </Box>
      <br></br>
      {controlledContentFrame()}
    </Box>
  );
};

