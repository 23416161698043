import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ReactComponent as StarIcon } from 'common/Styles/svg_icons/Icons_Filled_star_24px.svg';
import { BladeMenuItem } from "common/Types/BladeMenuTypes";
import { PreferredItem, UserPreferenceDTO } from "common/Types/UserPreferenceTypes";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUserFavorites, setUserPreference } from "../overview/preferences/preferencesSlice";
import styles from './favoriteControl.module.scss';
import { selectOidcUser, selectSessionId } from "gatewaySlice";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import configData from 'config.json';

interface FavoriteControlProps {
  preferredItem: BladeMenuItem;
  keyName: string;
  displayName: string;
  location: string;
}

export const FavoriteControl = (props: FavoriteControlProps): JSX.Element => {
  const { preferredItem, keyName, displayName, location } = props;
  const user = useAppSelector(selectOidcUser);
  const sessionId = useAppSelector(selectSessionId);
  const appInsights = useAppInsightsContext();
  const favorites = useAppSelector(selectUserFavorites);
  const dispatch = useAppDispatch();
  const reportName = preferredItem?.name ?? 'unavailable';
  const trackFavoriteAction = useTrackEvent(appInsights, "On Click of Favorite", {});
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const returnIsFavorite = (): boolean => {
    const faveNames = favorites.map((fave: PreferredItem) => fave.pageName);
    return faveNames.includes(keyName);
  };

  const isFavorite = returnIsFavorite();
  const preferenceDto: UserPreferenceDTO = {
    preferenceType: "favorite",
    preference: {
      menuId: preferredItem.id,
      pageName: keyName,
      isActive: !isFavorite,
      reportPack: preferredItem?.name ?? '',
      displayPageName: displayName,
    },
    item: preferredItem
  }

  const toggleFavorite = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setDisabled(true);
    await dispatch(setUserPreference(preferenceDto)).then(() => setDisabled(false));
    trackFavoriteAction({
      message: `User favorited or unfavorited report: ${reportName} Page: ${keyName}`,
      userName: user?.profile["name"],
      sessionId: sessionId,
      applicationName: configData.ENDPOINT_ALX_APP_NAME,
      environment: configData.ENDPOINT_ALX_APP_ENV,
      reportName,
      menuId: preferredItem.id,
      pageName: keyName,
      location: location,
    });
  }

  const iconClass = () => {
    return isFavorite ? styles.favorite : styles.non_favorite;
  };

  return (
    <Box onClick={(event) => toggleFavorite(event)} sx={{ display: "flex" }} className={`${isDisabled && styles.once}`} data-testid='favorite-control-container'>
      <StarIcon className={`${styles.fave_star} ${iconClass()}`} data-testid='favorite-control-icon' />
    </Box>
  );
}